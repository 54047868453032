<template>
    <div class="row">
        <div v-if="!isLoad" class="col-12">
            <div v-if="target != null" class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header pt-4 pl-3 m-0 bg-current">
                            <h3 class="card-title text-white"><i class="fas fa-plus mr-2"></i> Create Unit Target</h3>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="patchTarget()" class="row">
                                <div class="form-group col-lg-12">
                                    <label for="">Target</label>
                                    <input type="text" v-model="target.content" class="form-control" placeholder="Insert Unit Target..." required>
                                </div>
                                <div class="form-group col-12 text-right">
                                    <label for="">&nbsp;</label>
                                    <button type="submit" class="btn btn-current">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="card">
                <div class="card-body">
                    <div class="row py-2">
                        <div class="col-12 text-center my-2">
                            <img :src="'/images/empty.png'" alt="" width="300">
                            <h1 class="text-muted">Data don't exist</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="col-12 text-center py-3">
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            isLoad: true,
            paramsId: this.$route.params.idCourse,
            idSyllabus: this.$route.params.idSyllabus,
            idPoint: this.$route.params.idPoint,
            idTarget: this.$route.params.idTarget,
            target: null
        }
    },
    created(){
        this.getTarget();
    },
    methods: {
        async getTarget(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/syllabus/point/target/detail?id=${this.idTarget}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.isLoad = false
                this.target = res.data.data
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Unit Target',
                    text: 'Failed Load Unit Target!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
        async patchTarget(){
            var data ={
                _method: 'patch',
                id: this.target.id,
                point_id: this.idPoint,
                content: this.target.content,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus/point/target`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Unit Target',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SyllabusPointCoursesAdmin', params: { idCourse: this.paramsId, idsyllabus: this.idSyllabus, idPoint: this.idPoint } })
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus Unit',
                    text: 'Failed Create New Syllabus Unit!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
    }
}
</script>